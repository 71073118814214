import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Details from "./Details";

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  </Router>
);
const Home = () => (
  <>
    <div className="main_body">
    <h1 className="main_heading">Serpiente de Google</h1>
    <iframe
      className="frame"
      src="/game.html"
      title="Snake Game"
      scrolling="no"
    ></iframe>
  </div>
  <div className="details_body">
    <Details/>
  </div>
  </>
);

const PageNotFound = () => {
  return (
    <div className="pageNotFound">
      <h1>
        "Oops! The page you're looking for couldn't be found.Please check the
        URL and try again."
      </h1>
    </div>
  );
};

export default App;
